.loader-ios {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: iosIntro 0.6s;
}
.svg {
  fill: #868686;
}
.path1 {
  animation: pulse 1s infinite linear;
}
.path2 {
  animation: pulse 1s -0.083s infinite linear;
}
.path3 {
  animation: pulse 1s -0.166s infinite linear;
}
.path4 {
  animation: pulse 1s -0.249s infinite linear;
}
.path5 {
  animation: pulse 1s -0.332s infinite linear;
}
.path6 {
  animation: pulse 1s -0.415s infinite linear;
}
.path7 {
  animation: pulse 1s -0.498s infinite linear;
}
.path8 {
  animation: pulse 1s -0.581s infinite linear;
}
.path9 {
  animation: pulse 1s -0.664s infinite linear;
}
.path10 {
  animation: pulse 1s -0.747s infinite linear;
}
.path11 {
  animation: pulse 1s -0.83s infinite linear;
}
.path12 {
  animation: pulse 1s -0.913s infinite linear;
}

@keyframes pulse {
  50% {
    fill: #868686;
  }
  to {
    fill: rgba(134, 134, 134, 0.4);
  }
}

@keyframes iosIntro {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
