* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.button-style-reset {
  display: inline-block;
  border: none;
  margin: 0;
  padding: 0;
  font-family: sans-serif; /* Use whatever font-family you want */
  font-size: 1rem;
  line-height: 1;
  background: transparent;
  -webkit-appearance: none;
}
a:focus {
  outline: 3px solid #3d9970;
}
ul {
  list-style: none;
}
input {
  border: 2px solid #ccc;
  border-radius: var(--border-radius);
}
button {
  cursor: pointer;
  border-radius: var(--border-radius);
}
button:focus {
  border: 1px solid #333;
}
input:focus,
textarea:focus,
select:focus {
  outline: 1px solid #4c47476b;
}
.h100 {
  height: 100%;
}
.mw0 {
  min-width: 0;
}
.flex {
  display: flex;
}
.column {
  flex-direction: column;
}
.grow {
  flex: 1;
}
.grid {
  display: grid;
}
.center {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  -webkit-clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}
.hide {
  visibility: hidden;
}
:root {
  --input-padding: 5px;
  --border-radius: 4px;
  --header-padding: 1rem 2rem 1rem 1rem;
  --grey: #333333;
  --white: #ebebeb;
  --yellow: #ffde00;
  --shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.rounded-corner {
  border-radius: 4px;
}
.pella-button {
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 1rem;
  margin: 0;
  text-decoration: none;
  background: #ffde00;
  color: #000;
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
}
.pella-button:hover,
.pella-button:focus {
  background: #e7d457;
}
.pella-button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}
.modal {
  background: #fff;
  padding: 1rem;
}

/* Pagination css */
.pagination {
  align-self: center;
  display: flex;
  flex-wrap: wrap; /*for resposiveness in smaller screens*/
  cursor: pointer;
  color: black;
  padding: 1rem 1rem;
  text-decoration: none;
  transition: background 0.3s;
  border: none;
}
.active {
  background-color: #060f14;
  color: white;
}
.page,
.previous,
.next {
  border: 1px solid black;
  margin-bottom: 5px;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}
.breakLink {
  display: block;
  padding: 10px;
}

.disabledButton {
  cursor: not-allowed;
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}
.disabledButton > * {
  pointer-events: none;
}
.link {
  display: block;
  padding: 10px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scroll {
  overflow-x: auto;
}

.margin-bottom {
  margin-bottom: 10px;
}
.alctr {
  align-items: center;
}
.relative {
  position: relative;
}
