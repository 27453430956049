.form {
  background-color: #fff;
  padding: 20px;
  border-radius: var(--border-radius);
}

.input {
  background-color: var(--white);
  padding: var(--input-padding);
  font-size: 1rem;
  min-width: 212px;
  margin-bottom: 10px;
  width: 100%;
  border: 2px solid #ccc;
  border-radius: 5px;
}

.action {
  position: relative;
  left: 100%;
  transform: translateX(-100%);
}
