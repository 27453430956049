.confirm {
  padding: 10px;
}
.container {
  width: 100%;
  background-color: var(--white);
  align-items: center;
  justify-content: space-evenly;
}
.textCenter {
  text-align: center;
}

@media (max-width: 768px) {
  .container,
  .confirm {
    font-size: 0.75rem;
  }
}
.success {
  color: green;
}
.error {
  color: red;
}
