.avatar {
  background: #e1e1e1;
  padding: 2px 25px;
  border-radius: 10px;
  position: relative;
}
.username {
  display: inline-block;
  margin-right: 5px;
}
.action:hover {
  color: initial;
}

.avatarIcon {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 100%;
  transform: translate(-50%, -35%); /*25*/
}
.avatarpopup {
  box-shadow: var(--shadow);
  position: absolute;
  right: 0;
  top: 50px;
  background-color: #fff;
  padding: 20px;
}
.logout {
  background-color: var(--white);
  border: 1px solid var(--grey);
  margin-left: auto;
  align-items: center;
  margin-top: 10px;
  padding: 2px;
}
.changepass {
  background-color: var(--white);
  border: 1px solid var(--grey);
  padding: 6px;
  margin-bottom: 6px;
}
.gridContainer {
  grid-template-columns: 100px max-content;
}
.gridContainer h4 {
  padding-bottom: 10px;
}
.flexContainer {
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  padding-top: 10px;
}
.buttons {
  padding-top: 10px;
}
.center {
  margin: 0 auto;
  display: block;
}
.close {
  margin-left: auto;
  margin-top: -15px;
  margin-right: -15px;
}
.showPwdBtn {
  margin-left: 5px;
}
.eyeIcon {
  width: 20px;
  height: 20px;
}
.marginReset {
  margin-bottom: 0px;
}
.showPwdBtn:disabled {
  background-color: transparent;
}
.p {
  text-align: center;
  line-height: 1.5;
}
.yes {
  padding: 0.5rem 1.5rem;
  margin: 10px;
}
.buttons {
  text-align: center;
  padding-top: 10px;
}
.cancel {
  font-weight: 400;
  background: none;
  padding: 0.5rem;
  border: 1px solid;
  border-color: #ffde00;
  font-size: 1rem;
}
.form {
  width: 300px;
  padding: 10px;
}
