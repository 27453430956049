.modal {
  background: #fff;
  border-radius: var(--border-radius);
}
.body {
  padding: 1rem;
  max-height: calc(80vh - 50px);
  overflow: auto;
}
.header {
  color: #fff;
  background-color: var(--grey);
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  padding: 10px 10px;
  justify-items: center;
}

.title {
  margin-left: auto;
  margin-right: 5px;
}

.closeButton {
  margin-left: auto;
}
.closeIcon line {
  stroke: white;
}
.close {
  transform: translate(60%, -60%);
}
