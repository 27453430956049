.header {
  background-color: var(--grey);
  padding: var(--header-padding);
  position: relative;
  z-index: 1;
}
.logo {
  display: inline-block;
  position: absolute;
  top: 100%;
  transform: translateY(-50%);
}
.header img {
  width: 64px;
  height: 64px;
}
.nav {
  position: absolute;
  left: 10%;
}
.nav a {
  color: #fff;
  text-decoration: none;
  padding: 10px;
  border: 1px solid seagreen;
}
.active {
  background-color: rgb(25, 78, 48);
}
.main {
  background-color: var(--white);
  overflow: auto;
}
.floatRight {
  float: right;
}
